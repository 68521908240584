<template>
  <div>
    <div v-if="main_number">
      {{ main_number.number }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MainNumber",
  computed:{
    item(){
      return this.params.data
    },
    main_number(){
      return this.item.phone_numbers.find(item => item.main === true)
    }
  }
}
</script>

<style scoped>

</style>
