<template>
  <div>
    <div class="flex justify-between py-2">
      <a-button
          v-if="is_operator"
          type="primary"
          class="default-button mr-1 base_text flex justify-center items-center"
          size="small"
          @click="add_client">
        <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />
        <div class="ml-2">
          {{ $t('add_client_text') }}
        </div>

      </a-button>
    </div>

    <card>
      <AgGridTable
          ref="refAgGridTableClient"
          :query_name="'search_phone_number'"
          :column_defs="column_defs"
          :end_point="end_point"
          :name_event_bus="name_event_ebus"
          @open_drawer="open_drawer"/>
    </card>
    <client-and-request ref="refClientAndRequest" :title="$t('add_client_text')" @after_create="after_create" :name_event_bus="name_event_bus"/>
    <client-show-drawer ref="refClientShowDrawer" @open_drawer_request="open_drawer_request"/>
    <RequestShowDrawer ref="refRequestShowDrawer" @go_client="go_client"/>
  </div>
</template>

<script>
import FormSelectItems from "@/pages/AddClient/ClientMixins/FormSelectItems";
import FormAndAgGrid from "@/components/Forms/FormAddClient/FormAndAgGrid";
import ActionsClient from "@/components/Forms/ActionsClient";
import validate_iin from "@/Mixins/validate/validate_iin";
import validate_email from "@/Mixins/validate/validate_email";
import Card from "@/crm_components/card/Card";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import CardV2 from "@/crm_components/card_v2/CardV2";
import BigIcon from "@/crm_components/icons/BigIcon";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import CardDrawer from "@/crm_components/CustomDrawer/CardDrawer";
import InfoDrawer from "@/crm_components/CustomDrawer/InfoDrawer";
import FormattedDateMixin from "@/crm_components/mixins/FormattedDateMixin";
import ClientInfo from "@/pages/AddClient/Info/ClientInfo";
import ColorIcon from "@/crm_components/icons/ColorIcon";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import SelectView from "@/pages/CustomerRequest/SelectView";
import AddRequest from "@/pages/AddClient/AddRequest";
import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
import ClientShowDrawer from "@/pages/AddClient/Client/ClientShowDrawer";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import ClientAndRequest from "@/pages/AddClient/ClientAndRequest/ClientAndRequest";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin";
import MainNumber from "@/pages/AddClient/ag_grid/MainNumber";
import DopNumber from "@/pages/AddClient/ag_grid/DopNumber";
import OpenDrawerClientMixin from "@/pages/AddClient/ClientMixins/OpenDrawerClientMixin";
import AgGridTableNumber from "@/components/Forms/FormAddClient/AgGridTableNumber";
import RoleMixin from "@/Mixins/RoleMixin";

export default {
  name: "AddClientComponents",
  components: {
    DopNumber,
    MainNumber,
    ClientAndRequest,
    ClientShowDrawer,
    RequestShowDrawer,
    AddRequest,
    CustomTabs,
    ColorIcon,
    InfoDrawer,
    CardDrawer,
    CustomDrawer,
    BigIcon,
    CardV2,
    Card,
    FormAndAgGrid,
    ActionsClient,
    AgGridTable,
    FormSelect,
    AgGridTableNumber,
    ClientInfo
  },
  props:{
    show_form:{
      type:Boolean,
      default: true
    },
    ag_grid_height: {
      type: String,
      default: '450px'
    },
  },
  mixins:[
      validate_iin,
      validate_email,
      formSaveMixin,
      FormattedDateMixin,
      FormSelectItems,
      DeleteMixin,
      OpenDrawerRequestMixin,
      OpenDrawerClientMixin,
    RoleMixin
  ],
  data(){
    return {

      end_point:'organizations/profiles_client/',
      name_event_bus:'clients_event_bus',
      column_defs: [
        {
          headerName: "",
          field: "full_name",
          cellRendererFramework: 'ActionsClient',
          width: 50,
          cellRendererParams: {
            onEditClicked: params => {
              this.open_drawer({data:params.data}, true)
            },
            onDeleteClicked: params => {
              this.delete_method(this.name_event_bus, 'organizations/profiles_client/', params.data)
            },
            onWatchClicked: params => {
              this.open_drawer({data:params.data})
            },
          }
        },
        {
          headerName: "№",
          field: "full_name",
          minWidth: 75,
          maxWidth: 75,
          cellRendererFramework: 'AgGridTableNumber',
        },
        {
          headerName: "ФИО",
          field: "full_name",
          cellClass: ['a-link', 'cursor-pointer'],
          open_drawer:true,
        },
        {
          headerName: this.$t('data_created'),
          field: "created",
          // headerCheckboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // checkboxSelection: true,
          valueFormatter: function(params) {
            // Получаем дату из параметров
            var date = params.value;
            // Парсим дату в объект Date
            var formattedDate = new Date(date);
            // Получаем день, месяц и год
            var day = formattedDate.getDate().toString().padStart(2, '0');
            var month = (formattedDate.getMonth() + 1).toString().padStart(2, '0'); // Месяц начинается с 0
            var year = formattedDate.getFullYear().toString();
            // Формируем строку в формате "dd/MM/yyyy"
            var formattedDateString = `${day}.${month}.${year} в ${formattedDate.getHours()}:${formattedDate.getMinutes()}`;
            // Возвращаем отформатированную дату
            return formattedDateString;
          },
        },
        {
          headerName: "ИИН",
          field: "iin",
        },
        {
          headerName: "Email",
          field: "email",
        },
        {
          headerName: "Основной номер",
          field: "full_name",
          cellRendererFramework: 'MainNumber',
        },
        {
          headerName: this.$t('dop_numbers'),
          field: "full_name",
          cellRendererFramework: 'DopNumber',
        },
      ],

    }
  },
  methods:{
    clone_handle_submit(refForm, name_event_bus, end_point, action = 'create', data= null){
      this.handleSubmit(refForm, name_event_bus, end_point, action, data)
    },
    add_client(){
      this.$refs.refClientAndRequest.visible = true
    },
    after_create(val){
      this.$refs.refClientAndRequest.visible = false
      this.open_drawer({data:val.data.profile})
      this.$refs.refAgGridTableClient.updateMessage(val.data.profile)
    },
    go_client(val){
      this.open_drawer({data:val})
      console.log('val', val)
    }
  }
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.default-button{
  width: 200px;
  border-radius: $border-radius-base;
}
.save-button{

}
</style>
