<template>
 <div class="flex justify-between">
    <div v-for="item in dop_number" :key="item.uid">
      {{ item.number }}
    </div>
 </div>
</template>

<script>
export default {
  name: "DopNumber",
  computed:{
    item(){
      return this.params.data
    },
    dop_number(){
      return this.item.phone_numbers.filter(item => item.main === false)
    }
  }
}
</script>

<style scoped>

</style>
